import React from 'react';
import { observer } from 'mobx-react';

import ContentScreen from 'components/content-screen';
import CallerKioskName from './components/caller-kiosk-name';
import CallerInfo from './components/caller-info';
import Modal from 'components/modal';
import Button from 'components/button';

import { getConferenceStatusCaption } from './helpers';

import EndCallPhone from 'assets/icons/end-call-phone.svg';

import './video-conference-style.scss';


const VideoConferenceView = ({
  showingVideoConferenceModal,
  allowEndingCall,
  videoConferencePurpose,
  remoteTracks = {},
  conferenceStatus,
  connectingToTwilio,
  notificationPayload,
  prescriptionFillsForDisplay,
  leaveRoom,
  approveDispenseAttempt,
  rejectDispenseAttempt,
  isCallOver,
  isLeavingCall,
}) => {
  const conferenceStatusCaption = getConferenceStatusCaption(conferenceStatus);

  let filteredRemoteTracks = [];
  if (remoteTracks && remoteTracks.size > 0) filteredRemoteTracks = Array.from(remoteTracks.values())[0];
  filteredRemoteTracks = Array.from(filteredRemoteTracks.values());

  return (
    <Modal
      modalClassName='c-VideoConferenceModal'
      modalStyles={{ width: '1000px', height: 'auto' }}
      isOpen={showingVideoConferenceModal}
      shouldCloseOnOverlayClick={false}
    >
      <div className='video-conference-wrapper'>
        <div className='video-conference-container'>
          {
            isCallOver || isLeavingCall
              ? <div className='call-ended-container'>
                <h1 className='call-ended-text'>{ isLeavingCall ? 'Leaving call...' : 'Call has ended.' }</h1>
                <Button
                  className='end-call-button'
                  disabled={!allowEndingCall || isLeavingCall}
                  onClick={leaveRoom}
                  color={'red'}
                >
                  Leave
                </Button>
              </div>
              : <div className='video-conference'>
                {
                  conferenceStatusCaption
                    ? <div className='connection-status'>
                      {
                        conferenceStatusCaption
                      }
                    </div>
                    : <div className='remote-media' ref={(el) => {
                      if (!el) return;
                      filteredRemoteTracks.forEach((track) => {
                        el.appendChild(track.htmlRef);
                        track.htmlRef.play();
                      });
                    }}>
                      <CallerKioskName
                        notificationPayload={notificationPayload}
                      />
                      <ContentScreen active={connectingToTwilio} />
                    </div>
                }
                <button
                  className='end-call-button-container'
                  disabled={!allowEndingCall}
                  onClick={leaveRoom}
                >
                  <img
                    alt='end-call'
                    className='phone-icon'
                    src={EndCallPhone}
                  />
                  <div className='text'>
                    End
                  </div>
                </button>
              </div>
          }
        </div>
        <div className='call-info'>
          <CallerInfo
            videoConferencePurpose={videoConferencePurpose}
            notificationPayload={notificationPayload}
            prescriptionFillsForDisplay={prescriptionFillsForDisplay}
            connectingToTwilio={connectingToTwilio}
            approveDispenseAttempt={approveDispenseAttempt}
            rejectDispenseAttempt={rejectDispenseAttempt}
          />
        </div>
      </div>
    </Modal>
  );
};

export default observer(VideoConferenceView);
