import React from 'react';
import { observer } from 'mobx-react';

import CallInfo from '../call-info';

import AcceptCallIcon from 'assets/icons/phone-green.svg';
import RejectCallIcon from 'assets/icons/phone-red.svg';

import './pending-video-conference-style.scss';


const PendingVideoConferenceNotification = ({ videoConference, accept, reject }) => (
  <div key={videoConference.id} className='c-PendingVideoConferenceNotification'>
    <CallInfo
      videoConference={videoConference}
    />
    <div className='actions'>
      <img
        alt='accept'
        className='icon green-border'
        src={AcceptCallIcon}
        onClick={() => accept(videoConference)}
      />
      {/* <img
        alt='reject'
        className='icon red-border'
        src={RejectCallIcon}
        onClick={() => reject(videoConference)}
      /> */}
    </div>
  </div>
);

export default observer(PendingVideoConferenceNotification);
