import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { isolate } from 'bunchoid';

// Views
import KioskInventoryView from './kiosk-inventory-view';

// Hooks
import useSocketEvent from 'hooks/useSocketEvent';

// Helpers
import {
  fetchDrugsForActiveKiosk,
  filterInventory,
  setInventoryThreshold,
  sortInventory,
} from './helpers';
import { fetchComputedInventoryForModification } from 'models/kiosks/helpers';

// Constants
import { INVENTORY_REFETCH_INTERVAL } from './constants';

// Route store
import { kioskInventoryRouteStore } from './domain/store';

// Global Stores
import stores from 'bootstrap/store';
import { useQuery } from '@tanstack/react-query';
import { EVENT } from 'bootstrap/api-websocket/constants';
const kiosksStore = stores.global.kiosks;

const bunchoid = isolate();


const KioskInventory = ({ activeKiosk, isExportInventoryModalOpen, closeExportInventoryModal }) => {
  useEffect(() => {
    kioskInventoryRouteStore.setValue('isMounted', true);

    // TODO could feasibly have a way that we didn't have to reload inventory from clear every time
    // but it was causing problems to try to not have this wiped every time, so for now we clear
    kiosksStore.setValue('computedInventory', null);

    return () => {
      kioskInventoryRouteStore.setValue('isMounted', false);
      kiosksStore.setValue('computedInventory', null);
    };
  }, []);

  const fetchInventory = useQuery({
    queryKey: ['kiosk computedInventory', activeKiosk.id],
    queryFn: async (context) => {
      return Promise.all([
        fetchComputedInventoryForModification(),
        fetchDrugsForActiveKiosk(),
      ]);
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 60, // 1 hour
    refetchOnWindowFocus: 'always',
    refetchOnMount: 'always',
    refetchOnReconnect: true,
    refetchInterval: INVENTORY_REFETCH_INTERVAL,
  });

  useSocketEvent(EVENT.RECEIVED.CONTAINERS_ASSIGNABLE, () => {
    console.log('Containers assignable event received');
    return bunchoid(() => {
      fetchInventory.refetch();
    }, {
      wait: 2500,
      maxWait: 10000,
      key: ['refresh computed inventory for new container', activeKiosk.id],
    });
  }, []);


  if (!kioskInventoryRouteStore.isMounted || !kiosksStore.computedInventory) return <></>;

  return <KioskInventoryView
    kioskInventoryRouteStore={kioskInventoryRouteStore}
    inventoryLoaded={!!kiosksStore.computedInventory}
    filterInventory={filterInventory}
    sortInventory={sortInventory}
    setInventoryThreshold={setInventoryThreshold}
    isExportInventoryModalOpen={isExportInventoryModalOpen}
    closeExportInventoryModal={closeExportInventoryModal}
    fetchDrugs={() => stores.global.drugs.listForPharmacistByKiosk({ params: { kioskId: activeKiosk.id } })}
    fetchInventory={fetchInventory.refetch}
    activeKioskName={activeKiosk?.name}
  />;
};

export default observer(KioskInventory);
