import React from 'react';

import './buttonStyles.scss';

function Button({
  className,
  color = 'blue',
  children,
  ...PROPS
}) {
  return <button
    className={`c-Button ${color} ${className}`}
    {...PROPS}
  >
    { children }
  </button>;
}

export default Button;
