import { flow, types } from 'mobx-state-tree';
import {
  keyBy as _keyBy,
} from 'lodash';

import base from 'models/base';

import groupBy from 'utils/groupBy';

import { MODEL_NAME, SCHEMA } from './constants/schema';

import { buildAppCommand } from 'components/app-commands/helpers/app-command-builder';
import { prepareListedAppCommands } from './helpers';

const AppCommandModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {
    }
  }
});

let listedForKiosk = null;

AppCommandModel.configureStore((store) => {
  return store
  .props({
    dataForKiosk: types.optional(types.map(types.frozen()), {}),
    dataForHealthcareSite: types.optional(types.map(types.frozen()), {}),
    dataForMachineTechnician: types.optional(types.map(types.frozen()), {}),
    dataForPharmacyTechnician: types.optional(types.map(types.frozen()), {}),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listForKiosk: flow(function* (config = {}) {
      config.urlFragment = () => `/kiosks/${config.params.kioskId}`;
      const response = yield self.list(config);
      let data = response.data || response || [];
      data = prepareListedAppCommands(data);

      if (
        config.resetData ||
        (listedForKiosk !== config.params.kioskId)
      ) self.dataForKiosk.clear();

      listedForKiosk = config.params.kioskId;

      self.dataForKiosk.merge(_keyBy(data, 'id'));

      return response;
    }),
    listForHealthcareSite: flow(function* (config = {}) {
      config.urlFragment = () => `/healthcare-sites/${config.params.healthcareSiteId}`;
      const response = yield self.list(config);
      let data = response.data || response || [];
      data = prepareListedAppCommands(data);

      if (config.resetData) self.dataForHealthcareSite.clear();

      self.dataForHealthcareSite.merge(_keyBy(data, 'id'));

      return response;
    }),
    listForMachineTechnician: flow(function* (config = {}) {
      config.urlFragment = (params) => `/machine-technicians/${params.machineTechnicianId}`;
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForMachineTechnician.merge(_keyBy(data, 'id'));
      return response;
    }),
    listForPharmacyTechnician: flow(function* (config = {}) {
      config.urlFragment = (params) => `/pharmacy-technicians/${params.pharmacyTechnicianId}`;
      const response = yield self.list(config);
      const data = response.data || response || [];
      self.dataForPharmacyTechnician.merge(_keyBy(data, 'id'));
      return response;
    }),
  }))
  .views(self => ({
    appCommandsByDateByType({ type, displayableOnly = false } = {}) {
      const list = Array.from(self.dataForKiosk.values());

      return groupBy(list, {
        formatKey: (key) => new Date(key).toDateString(),
        preOrder: {field: 'createdAt', order: 'desc'},
        preValidate: (appCommand) => (type && appCommand.type === type) || displayableOnly && buildAppCommand(appCommand),
        path: 'createdAt',
        keyName: 'date'
      });
    },
    appCommandsByDateByTypeForHealthcareSite({ type, displayableOnly = false } = {}) {
      const list = Array.from(self.dataForHealthcareSite.values());

      return groupBy(list, {
        formatKey: (key) => new Date(key).toDateString(),
        preOrder: {field: 'createdAt', order: 'desc'},
        preValidate: (appCommand) => (type && appCommand.type === type) || displayableOnly && buildAppCommand(appCommand),
        path: 'createdAt',
        keyName: 'date'
      });
    },
    get appCommandsForMachineTechnicianByDate() {
      const list = Array.from(self.dataForMachineTechnician.values());

      return groupBy(list, {
        formatKey: (key) => new Date(key).toDateString(),
        preOrder: {field: 'createdAt', order: 'desc'},
        path: 'createdAt',
        keyName: 'date'
      });
    },
    get appCommandsForPharmacyTechnicianByDate() {
      const list = Array.from(self.dataForPharmacyTechnician.values());

      return groupBy(list, {
        formatKey: (key) => new Date(key).toDateString(),
        preOrder: {field: 'createdAt', order: 'desc'},
        path: 'createdAt',
        keyName: 'date'
      });
    },
  }));
});


export default AppCommandModel;
