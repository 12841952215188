import retry from 'bluebird-retry';
import { flow, types } from 'mobx-state-tree';
import {
  get as _get,
  keyBy as _keyBy,
  orderBy as _orderBy,
} from 'lodash';
import { EventEmitter } from 'events';

import stores from 'bootstrap/store';
import base from 'models/base';
import helpers from './helpers';
import { MODEL_NAME, SCHEMA } from './constants/schema';
import {
  CONFERENCE_STATUS,
  SETTING_UP_CONFERENCE_STATUSES,
} from './constants';


const VideoConferenceModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

export const emitter = new EventEmitter();

VideoConferenceModel.configureStore((store) => {
  return store
  .props({
    showingVideoConferenceModal: types.optional(types.boolean, false),
    activePendingConference: types.maybeNull(types.frozen()),

    // Active & pending conferences keyed by videoConferenceId.
    pending: types.optional(types.map(types.frozen()), {}),

    // Conferences keyed by purpose + label.
    conferences: types.optional(types.map(
      types.model({
        purpose: types.maybeNull(types.string),
        label: types.maybeNull(types.string),
        videoConference: types.frozen(),
        participantConnection: types.frozen(),
        pingInterval: types.frozen(),
        notificationPayload: types.frozen(),
        isLeaving: types.optional(types.boolean, false),
      })
    ), {}),

    // Conference and Twilio connection statuses keyed by purpose + label.
    conferenceStatuses: types.optional(types.map(
      types.enumeration('Conference Status', Object.values(CONFERENCE_STATUS))
    ), {}),

    // Twilio metadata keyed by twilio token.
    twilio: types.optional(types.map(
      types.model({
        room: types.frozen(),
        localTracks: types.optional(types.map(types.frozen()), {}),
        // TODO consider moving all of the rest of these twilio props (tracks, particpants)
        // onto a map keyed by conferenceId rather than twilio token id
        // Room participants keyed by participant sid
        remoteTracksByParticipant: types.optional(types.map(
          types.optional(types.map(types.frozen()), {})
        ), {})
      })
    ), {}),

    // Video Calls History
    videoCallLog: types.optional(types.frozen(), []),
  })
  .actions(self => ({
    /******************************************************************************/
    /*******************************  API METHODS  ********************************/
    /******************************************************************************/
    create: ({ label, purpose, auxiliaryData = {} }, { twilioOpts } = {}) => {
      if (!purpose) return Promise.reject('purpose must be provided in order to create a video conference.');

      const key = purpose + (label ? `_${label}` : '');

      // Determine if there is an existing conference currently connecting.
      const existing = self.conferences.get(key);
      if (existing && self.isSettingUpConference(key)) return existing;

      // Delete existing.
      if (existing) self.leaveTotally({ key });

      const initialRoute = stores.router.location.pathname;

      self.setConferenceStatus(key, CONFERENCE_STATUS.CONNECTING_API_VIDEO_CONFERENCE);

      return self.post({ body: { purpose, auxiliaryData } })
      .catch(() => helpers.handleVideoConferenceApiConnectFailure(self, key))
      .then((response) => helpers.handleVideoConferenceApiConnect(self, response, {
        key,
        label,
        purpose,
        initialRoute
      }, { twilioOpts }));
    },
    connect: ({ label, purpose, videoConferenceId }, { twilioOpts } = {}) => {
      if (!purpose) return Promise.reject('purpose must be provided in order to connect to a video conference.');

      const key = purpose + (label ? `_${label}` : '');

      // Determine if there is an existing conference currently connecting.
      const existing = self.conferences.get(key);
      if (existing && self.isSettingUpConference(key)) return existing;

      // Delete existing.
      if (existing) self.leaveTotally({ key });

      const initialRoute = stores.router.location.pathname;

      self.setConferenceStatus(key, CONFERENCE_STATUS.CONNECTING_API_VIDEO_CONFERENCE);

      return self.put({
        urlFragment: () => `/${videoConferenceId}/connect`,
        params: { videoConferenceId },
      })
      .catch(() => helpers.handleVideoConferenceApiConnectFailure(self, key))
      .then((response) => helpers.handleVideoConferenceApiConnect(self, response, {
        key,
        label,
        purpose,
        initialRoute
      }, { twilioOpts }));
    },
    getDetails: ({ videoConferenceId }) => {
      return self.get({ urlFragment: () => `/${videoConferenceId}` });
    },
    leaveRoom: (config = {}) => {
      config.urlFragment = params => `/${params.videoConferenceId}/disconnect`;
      return self.put(config);
    },
    leaveTotally: async({ key, twilioToken }, { preserveStatus = false } = {}) => {
      if (!key) return;
      const promises = [];

      const conference = self.conferences.get(key) || {};
      console.log('CONFERENCE', conference, key);

      if (conference.isLeaving) return;
      conference.isLeaving = true;

      // Wait to finish connecting before leaving.
      const maxWait = 60_000;
      const startedAt = Date.now();
      console.log('AAAAA', self.isConnectingToTwilio(key));
      while (self.isConnectingToTwilio(key)) {
        if ((Date.now() - startedAt) > maxWait) break;
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      const videoConferenceId = _get(conference, 'videoConference.id');
      if (videoConferenceId) promises.push(self.leaveRoom({ params: { videoConferenceId } }));

      // Leave Twilio Room
      while (!twilioToken) {
        twilioToken = self.getTwilioToken(key);
        if ((Date.now() - startedAt) > maxWait) break;
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      
      const twilio = self.twilio.get(twilioToken) || {};
      if (twilio.room) promises.push(helpers.leaveTwilioRoom(twilio.room));

      if (!preserveStatus) self.deleteConferenceStatus(key);
      if (promises.length) {
        const pendingConference = self.pending.get(key);
        const pendingConferenceId = _get(pendingConference, 'videoConference.id');
        if (pendingConferenceId === self.activePendingConference?.id) self.setValue('activePendingConference', null);

        helpers.cleanUp(self, { key, twilioToken });
      }

      return retry(() => Promise.all(promises), {
        interval: 3000,
        max_tries: 3,
      })
      .then(() => !preserveStatus && self.listActive());
    },
    listActive: flow(function* (config = {}) {
      // config.urlFragment = () => '/active';
      config.urlFragment = () => '/pending/for-pharmacist';
      const response = yield self.get(config);
      // const data = response.data || response || [];
      const data = response || [];

      self.pending = _keyBy(data, 'id');

      return response;
    }),
    listPendingForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/pending/for-pharmacist';
      const response = yield self.get(config);
      const data = response || [];

      self.pending = _keyBy(data, 'id');

      return response;
    }),
    listCallLogHistory: flow(function* (config = {}) {
      config.urlFragment = () => '/call-log';
      const response = yield self.get(config);
      const data = response.data || response || [];
      self.setValue('videoCallLog', data);

      return response;
    }),

    /******************************************************************************/
    /***************************  STATE MANIPULATION  *****************************/
    /******************************************************************************/
    setValue: (prop, value) => self[prop] = value,

    // self.conferences
    setConference: (key, content) => {
      const currentValue = self.conferences.get(key) || {};
      self.conferences.set(key, { ...currentValue, ...content });
    },
    deleteConference: (key) => {
      const conference = self.conferences.get(key);
      if (!conference) return;
      clearInterval(conference.pingInterval);
      self.conferences.delete(key);
    },

    // self.conferenceStatuses
    setConferenceStatus: (key, status) => self.conferenceStatuses.set(key, status),
    deleteConferenceStatus: (key) => self.conferenceStatuses.delete(key),

    // self.twilio
    setTwilio: (twilioToken, content) => {
      const currentValue = self.twilio.get(twilioToken) || {};
      self.twilio.set(twilioToken, { ...currentValue, ...content });
    },
    deleteTwilio: (twilioToken) => self.twilio.delete(twilioToken),

    // self.twilio.localTracks
    setTwilioLocalTracks: (twilioToken, localTracks) => {
      const twilio = self.twilio.get(twilioToken);
      if (!twilio || !localTracks || !localTracks.length) return;

      localTracks.forEach(localTrack => {
        if (localTrack.attach) localTrack.htmlRef = localTrack.attach();

        twilio.localTracks.set(localTrack.id, localTrack);
      });
    },
    deleteTwilioLocalTracks: (twilioToken) => {
      const twilio = self.twilio.get(twilioToken);
      if (!twilio || !twilio.localTracks.size) return;

      const tracks = Array.from(twilio.localTracks.values());
      helpers.detachTracks(tracks);

      if (twilio.room && twilio.room.localParticipant) twilio.room.localParticipant.unpublishTracks(tracks);

      twilio.localTracks.clear();
    },

    // self.twilio.participants
    setTwilioParticipant: (twilioToken, participantId) => {
      const twilio = self.twilio.get(twilioToken);
      if (!twilio) return;

      twilio.remoteTracksByParticipant.set(participantId, {});
    },
    deleteTwilioParticipantTracks: (twilioToken, participantId) => {
      const twilio = self.twilio.get(twilioToken);
      if (!twilio) return;

      const tracksByParticipant = twilio.remoteTracksByParticipant.get(participantId);

      if (tracksByParticipant && tracksByParticipant.size > 0)
        Array.from(tracksByParticipant.values())
        .forEach(track =>
          self.deleteTwilioParticipantTrack(twilioToken, participantId, track)
        );

      twilio.remoteTracksByParticipant.delete(participantId);
    },
    deleteTwilioParticipantTrack: (twilioToken, participantId, track) => {
      const twilio = self.twilio.get(twilioToken);
      if (!twilio) return;
      const remoteTracks = twilio.remoteTracksByParticipant.get(participantId);
      if (!remoteTracks) return;

      remoteTracks.delete(track.sid);
    },
    setTwilioParticipantTrack: (twilioToken, participantId, track) => {
      const twilio = self.twilio.get(twilioToken);
      if (!twilio) return;
      if (track.attach) track.htmlRef = track.attach();

      if (!twilio.remoteTracksByParticipant.has(participantId))
        twilio.remoteTracksByParticipant.set(participantId, {});

      const remoteTracks = twilio.remoteTracksByParticipant.get(participantId);
      if (remoteTracks) remoteTracks.set(track.sid, track);
    },
  }))
  .views(self => ({
    isSettingUpConference: (key) => {
      const status = self.conferenceStatuses.get(key) || '';
      return SETTING_UP_CONFERENCE_STATUSES.includes(status);
    },
    isConnectingToTwilio: (key) => {
      const status = self.conferenceStatuses.get(key);
      return status === CONFERENCE_STATUS.CONNECTING_TWILIO;
    },
    getTwilioToken: (key) => {
      const active = self.conferences.get(key);
      if (!active || !active.participantConnection) return;
      return active.participantConnection.twilioToken;
    },
    getTwilioConnection: (key) => {
      const twilioToken = self.getTwilioToken(key);
      return self.twilio.get(twilioToken);
    },

    get pendingArray() {
      return Array.from(self.pending.values());
    },
    get activeConferences() {
      // return self.pendingArray.filter((conference) => _get(conference, 'videoConferenceParticipantConnections.length') === 1);
      // return self.pendingArray;
      return [];
    },
    isActiveConference: (videoConferenceId) => {
      const conference = self.pending.get(videoConferenceId);
      return self.activeConferences.includes(conference);
    },
    get pendingInvitations() {
      return self.pendingArray.filter((conference) => !!_get(conference, 'videoConferenceInvitations.length'));
    },
    // Get all pending conferences, stacking active calls on top of invitations.
    get pendingConferences() {
      return self.activeConferences.concat(self.pendingInvitations);
    },

    callLogHistory(sortField, direction, limit = 20, page = 1) {
      const offset = (page - 1) * limit;
      return _orderBy(self.videoCallLog, [sortField], [direction]).slice(offset, offset + limit);
    },
  }));

});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default VideoConferenceModel;
