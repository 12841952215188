import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

// Views
import VideoConferenceView from './video-conference-view';

// Helpers
import {
  approveDispenseAttempt,
  connect,
  rejectDispenseAttempt,
} from './helpers';

// Stores
import stores from 'bootstrap/store';
import { videoConferencesRouteStore } from './domain/store';
import useSocketEvent from 'hooks/useSocketEvent';
import { EVENT } from 'bootstrap/api-websocket/constants';
const dispenseAttemptsStore = stores.global.dispenseAttempts;
const videoConferencesStore = stores.global.videoConferences;

const VideoConferenceModalContainer = () => {
  const [isLonely, setIsLonely] = useState(false);

  useEffect(() => {
    const videoConference = videoConferencesStore.activePendingConference || {};

    console.log('ACTIVE PENDING CONFERENCE', videoConference);
    connect(videoConference);

    return () => {
      dispenseAttemptsStore.setValue('remoteDispense', {});
    };
  }, []);

  useSocketEvent(EVENT.RECEIVED.VIDEO_CONFERENCE_COMPUTED_LONELY_UPDATE, async () => {
    let currentActiveId = _get(videoConferencesStore.activePendingConference, 'id');
    if (!currentActiveId) return;
    const result = await videoConferencesStore.getDetails({ videoConferenceId: currentActiveId });
    if (!result) return;
    const { computed_lonely } = result;
    setIsLonely(computed_lonely);
  });

  const leaveRoom = useCallback(async () => {
    const purpose = _get(videoConferencesStore.activePendingConference, 'purpose.name');
    await videoConferencesStore.leaveTotally({ key: purpose });
    videoConferencesStore.setValue('showingVideoConferenceModal', false);
  }, []);


  const purpose = _get(videoConferencesStore.activePendingConference, 'purpose.name');
  const conferenceStatus = videoConferencesStore.conferenceStatuses.get(purpose);
  const twilio = videoConferencesStore.getTwilioConnection(purpose) || {};
  const allowEndingCall = videoConferencesRouteStore.allowEndingCall(purpose);
  const isConnectingToTwilio = videoConferencesStore.isSettingUpConference(purpose);

  const isCallOver = useMemo(() => {
    return isLonely && !isConnectingToTwilio;
  }, [isLonely, isConnectingToTwilio]);

  const conference = videoConferencesStore.conferences.get(purpose);

  const isLeavingCall = conference?.isLeaving;

  return <VideoConferenceView
    showingVideoConferenceModal={videoConferencesStore.showingVideoConferenceModal}
    allowEndingCall={allowEndingCall}
    videoConferencePurpose={purpose}
    remoteTracks={twilio.remoteTracksByParticipant}
    conferenceStatus={conferenceStatus}
    connectingToTwilio={isConnectingToTwilio}
    notificationPayload={_get(videoConferencesStore, 'activePendingConference.payload', {})}
    prescriptionFillsForDisplay={videoConferencesRouteStore.prescriptionFillsForDisplay}
    leaveRoom={leaveRoom}
    approveDispenseAttempt={approveDispenseAttempt}
    rejectDispenseAttempt={rejectDispenseAttempt}
    isCallOver={isCallOver}
    isLeavingCall={isLeavingCall}
  />;
};


export default observer(VideoConferenceModalContainer);
