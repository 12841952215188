import Video, {
  createLocalAudioTrack,
  createLocalVideoTrack,
} from 'twilio-video';
import retry from 'bluebird-retry';
import {
  defaultsDeep as _defaultsDeep,
  get as _get,
} from 'lodash';

import stores from 'bootstrap/store';
import {
  CONFERENCE_STATUS,
  PING_INTERVAL,
  TWILIO_CONNECTION_TIMEOUT,
} from '../constants';

import { CAMERA_TYPES } from 'models/cameras/constants';


const videoConferenceHelpers = {};
const initialRouteIsActive = (initialRoute) => initialRoute === stores.router.location.pathname;


/**
 * Handle API video conference creation/joining.
 *
 * @param self - video conferences store
 * @param response - conference creating/joining server response
 * @param key - key to get conference by
 * @param label - any string to complete a key
 * @param purpose - video conference's purpose
 * @param initialRoute - route from which conference's creation/joining began
 * @param options
 */
videoConferenceHelpers.handleVideoConferenceApiConnect = (self, response = {}, { key, label, purpose, initialRoute } = {}, options = {}) => {
  console.log('HANDLING CONNECT', key, initialRoute, self.conferences.get(key));
  // If route from which the view has been opened and current route are different, disconnect.
  initialRoute = initialRoute || stores.router.location.pathname;
  if (!initialRouteIsActive(initialRoute)) return self.leaveTotally({ key });

  const {
    videoConference,
    videoConferenceParticipantConnection: participantConnection
  } = response;
  const { twilioOpts } = options;

  self.setConferenceStatus(key, CONFERENCE_STATUS.CONNECTED_API_VIDEO_CONFERENCE);
  self.setConference(key, { videoConference, participantConnection, label, purpose });

  videoConferenceHelpers.setUpConference(self, key);

  return videoConferenceHelpers.connectTwilio(self, { key, initialRoute }, twilioOpts);
};


/**
 * Handle video conference creating/joining failure.
 *
 * @param self - video conferences store
 * @param key - key to get conference by
 */
videoConferenceHelpers.handleVideoConferenceApiConnectFailure = (self, key) => {
  self.setConferenceStatus(key, CONFERENCE_STATUS.FAILED_CONNECTING_API_VIDEO_CONFERENCE);
  return Promise.reject();
};


/**
 * Set up conference's processes.
 *
 * @param self - video conferences store
 * @param key - key to get conference by
 */
videoConferenceHelpers.setUpConference = (self, key) => {
  // Start ping process.
  const conference = self.conferences.get(key) || {};
  const { participantConnection = {} } = conference;
  if (!participantConnection.id) return;

  self.setConference(key, {
    pingInterval: videoConferenceHelpers.pingRoomInterval(participantConnection.id),
  });
};


/**
 * Connect to Twilio. Create local audio & video tracks, if not told otherwise.
 *
 * @param self - video conferences store
 * @param key - key to get conference by
 * @param initialRoute - route from which the first helper has been called
 * @param options - Twilio connection options
 */
videoConferenceHelpers.connectTwilio = (self, { key, initialRoute }, options = {}) => {
  // If we're already in the process of connecting, return existing.
  if (self.isConnectingToTwilio(key)) return self.getTwilioConnection(key);

  const twilioToken = self.getTwilioToken(key);

  const audioEnabled = options.audio !== false;
  const videoEnabled = options.video !== false;

  const { videoConference } = self.conferences.get(key);
  // Local participant's track detaching and stopping will be handled by twilio-video
  // itself, as we don't pass created local tracks to connect.
  options = _defaultsDeep({}, options, {
    name: videoConference.id,
    audio: audioEnabled,
    video: videoEnabled,
  });

  self.setConferenceStatus(key, CONFERENCE_STATUS.CONNECTING_TWILIO);

  let localVideoTrackOptions = {};

  if (stores.global.cameras.deviceByTypes[CAMERA_TYPES.CONSULTATION])
    localVideoTrackOptions.deviceId = stores.global.cameras.deviceByTypes[CAMERA_TYPES.CONSULTATION];

  return Promise.all([
    createLocalAudioTrack(),
    createLocalVideoTrack(localVideoTrackOptions),
  ])
  .then((tracks) => {
    options.tracks = tracks;

    return retry(() => Video.connect(twilioToken, options), {
      timeout: TWILIO_CONNECTION_TIMEOUT,
    })
    .catch((err) => {
      self.setConferenceStatus(key, CONFERENCE_STATUS.FAILED_CONNECTING_TWILIO);
      self.leaveTotally({ key, twilioToken }, { preserveStatus: true });
      // Clean up tracks here, because "twilio" isn't created in store
      // and tracks are not attached because of connection failure.
      tracks.forEach((track) => track.stop());
      return Promise.reject(err);
    })
    .then((room) => videoConferenceHelpers.handleTwilioConnected(self, { key, twilioToken, initialRoute }, room, tracks));
  });
};


/**
 * Set up event listeners for a Twilio room.
 *
 * @param self - video conferences store
 * @param key - key to get conference by
 * @param twilioToken - key to get twilio object by
 * @param initialRoute - route from which video conference has been initialized
 * @param room - created Twilio room
 * @param localTracks
 */
videoConferenceHelpers.handleTwilioConnected = (self, { key, twilioToken, initialRoute }, room, localTracks) => {
  self.setConferenceStatus(key, CONFERENCE_STATUS.CONNECTED_TWILIO);

  // Initialize twilio object in state, because room can not be stored in state before its event listeners are defined.
  self.setTwilio(twilioToken, {});

  self.setTwilioLocalTracks(twilioToken, localTracks);
  room.participants.forEach((participant) => videoConferenceHelpers.handleTwilioParticipantConnected(self, twilioToken, participant));

  room.on('participantConnected', (participant) => videoConferenceHelpers.handleTwilioParticipantConnected(self, twilioToken, participant));
  room.on('participantDisconnected', (participant) => videoConferenceHelpers.twilioParticipantDisconnected(self, twilioToken, participant));
  room.on('disconnected', () => videoConferenceHelpers.cleanUp(self, { key, twilioToken }));

  // Set Twilio room object in state, so that it can be used to disconnect from Twilio later.
  self.setTwilio(twilioToken, { room });

  // If route from which the view has been opened and current route are different, disconnect.
  // Can't use it earlier, because called function uses Twilio room stored in state.
  if (!initialRouteIsActive(initialRoute)) return self.leaveTotally({ key, twilioToken });
};


/**
 * Set up event listeners for Twilio room's participant.
 *
 * @param self - video conferences store
 * @param twilioToken - key to get twilio object from state
 * @param participant - Twilio room participant
 */
videoConferenceHelpers.handleTwilioParticipantConnected = (self, twilioToken, participant) => {
  participant.tracks.forEach(publication => {
    videoConferenceHelpers.handleTwilioTrackSubscribed(self, twilioToken, { participant, publication });
  });
  participant.on('trackPublished', (publication) => videoConferenceHelpers.handleTwilioTrackSubscribed(self, twilioToken, { participant, publication }));
  participant.on('trackUnpublished', (publication) => videoConferenceHelpers.handleTwilioTrackUnsubscribed(self, twilioToken, { participant, publication }));

  participant.tracks.forEach((publication) => {
    if (!publication.isSubscribed) return;
    videoConferenceHelpers.handleTwilioTrackSubscribed(self, twilioToken, { participant, track: publication.track });
  });
};


/**
 *
 * @param self - video conferences store
 * @param twilioToken - key to get twilio object from state
 * @param participant - Twilio room participant
 * @param track - participant's track (audio or video) he's subscribed to a room
 */
videoConferenceHelpers.handleTwilioTrackSubscribed = (self, twilioToken, { participant, publication }) => {
  publication.on('subscribed', (track) => {
    self.setTwilioParticipantTrack(twilioToken, participant.sid, track);
  });

  publication.on('unsubscribed', track => {
    videoConferenceHelpers.detachTracks([track]);

    self.deleteTwilioParticipantTrack(twilioToken, participant.sid, track);
  });
};


/**
 * Detach participant's tracks and delete participant from state.
 *
 * @param self - video conferences store
 * @param twilioToken - key to get twilio object from state
 * @param participant - Twilio room participant
 */
videoConferenceHelpers.twilioParticipantDisconnected = (self, twilioToken, participant) => {
  videoConferenceHelpers.detachParticipantTracks(participant);
  self.deleteTwilioParticipantTracks(twilioToken, participant.sid);
};


/**
 * Detach participant's track he's unsubscribed from Twilio room.
 *
 * @param self - video conferences store
 * @param twilioToken - key to get twilio object from state
 * @param participant - Twilio room participant
 * @param track - participant's track (audio or video) he's subscribed to a room
 */
videoConferenceHelpers.handleTwilioTrackUnsubscribed = (self, twilioToken, { participant, track }) => {
  videoConferenceHelpers.detachTracks([track]);

  self.deleteTwilioParticipantTrack(twilioToken, participant.sid, track);
};


/**
 * Ping room every <interval> milliseconds.
 *
 * @param videoConferenceParticipantConnectionId
 * @param interval
 */
videoConferenceHelpers.pingRoomInterval = (videoConferenceParticipantConnectionId, { interval } = {}) => {
  if (!videoConferenceParticipantConnectionId) return;

  return setInterval(() => {
    const videoConferenceParticipantConnectionsStore = stores.global.videoConferenceParticipantConnections;
    return videoConferenceParticipantConnectionsStore.ping({
      params: {
        videoConferenceParticipantConnectionId,
      },
    });
  }, interval || PING_INTERVAL);
};


/**
 * Detach and stop participant's tracks.
 *
 * @param participant - Twilio room participant
 */
videoConferenceHelpers.detachParticipantTracks = (participant) => {
  if (!participant || !participant.tracks) return;
  const tracks = Array.from(participant.tracks.values()).map((tr) => tr.track);
  videoConferenceHelpers.detachTracks(tracks);
};


/**
 * Detach and stop tracks.
 *
 * @param tracks
 */
videoConferenceHelpers.detachTracks = (tracks = []) => {
  tracks.forEach((track) => {
    if (!track) return;

    if (track.detach && typeof track.detach === 'function') {
      const mediaElements = track.detach();
      mediaElements.forEach(mediaElement => mediaElement.remove());
    }
    if (track.mediaStreamTrack && typeof track.mediaStreamTrack.stop === 'function') track.mediaStreamTrack.stop();
  });
};


/**
 * Leave Twilio room.
 *
 * @param room - room to disconnect from. Must contain "disconnect" method.
 */
videoConferenceHelpers.leaveTwilioRoom = (room) => {
  if (typeof room.disconnect !== 'function') {
    return Promise.reject('Could not leave Twilio room. Passed room must contain "disconnect" method.');
  }

  return Promise.resolve(room.disconnect());
};


/**
 * Clean up conference and twilio.
 *
 * @param self - video conferences store
 * @param key - key to get conference by
 * @param twilioToken - key to get twilio object by
 */
videoConferenceHelpers.cleanUp = (self, { key, twilioToken }) => {
  twilioToken = twilioToken || self.getTwilioToken(key);
  videoConferenceHelpers.cleanUpTwilio(self, twilioToken);
  videoConferenceHelpers.cleanUpVideoConference(self, key);
};


/**
 * Stop pinging API. Remove conference from store.
 *
 * @param self - video conferences store
 * @param key - key to get conference by
 */
videoConferenceHelpers.cleanUpVideoConference = (self, key) => {
  const conference = self.conferences.get(key) || {};
  clearInterval(conference.pingInterval);
  self.deleteConference(key);
};


/**
 * Detach tracks of all the participants of a Twilio room.
 * Delete participants from store.
 * Delete twilio from store.
 *
 * @param self - video conferences store
 * @param twilioToken - key to get twilio object from state
 */
videoConferenceHelpers.cleanUpTwilio = (self, twilioToken) => {
  const twilio = self.twilio.get(twilioToken);
  if (!twilio) return;

  if (_get(twilio, 'room.participants')) {
    twilio.room.participants.forEach((participant) => {
      return videoConferenceHelpers.twilioParticipantDisconnected(self, twilioToken, participant);
    });
  }

  self.deleteTwilioLocalTracks(twilioToken);
  self.deleteTwilio(twilioToken);
};


export default videoConferenceHelpers;
