import { io } from 'socket.io-client';
import { API_BASE } from 'constants/index';

export const apiWebSocket = io(API_BASE, {
  // Reconnection settings
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
  
  // Transport settings
  transports: ['websocket', 'polling'],  // Try websocket first
  upgrade: true,  // Allow transport upgrades
  
  // Timeout settings
  timeout: 20000,  // Connection timeout
  
  // Performance & reliability
  forceNew: false,  // Reuse existing connection if available
  multiplex: true,  // Allow connection sharing
  
  withCredentials: true,  // Only if your server has cors: { credentials: true }
  
  // Optional but useful for debugging
  autoConnect: true,  // Connect automatically (default true)
});

// Track connection state
let isConnected = false;

apiWebSocket.on('connect', () => {
  console.log('Connected to WebSocket');
  isConnected = true;
  
  // Resubscribe to events or reinitialize state if needed
  if (apiWebSocket.recovered) {
    console.log('Connection recovered - no need to restore state');
  } else {
    console.log('New connection - restoring state');
  }
});

apiWebSocket.on('disconnect', (reason) => {
  console.log('Disconnected from WebSocket:', reason);
  isConnected = false;
  
  // Handle specific disconnect reasons
  if (reason === 'io server disconnect') {
    // Server initiated disconnect, need to manually reconnect
    return manualReconnect({ delay: 0 });
  }
  // Other reasons like 'transport close' or 'ping timeout' will auto-reconnect,
  // but just in case, we can manually reconnect after a delay
  manualReconnect();
});

apiWebSocket.on('error', error => {
  console.error('WebSocket error:', error);
});

apiWebSocket.on('connect_error', error => {
  console.error('WebSocket connection error:', error);
  
  manualReconnect();
});

apiWebSocket.on('reconnect', (attemptNumber) => {
  isConnected = true;
  console.log('Reconnected after', attemptNumber, 'attempts');
});

apiWebSocket.on('reconnect_attempt', (attemptNumber) => {
  console.log('Attempting reconnection:', attemptNumber);
});

apiWebSocket.on('reconnect_error', (error) => {
  console.error('Reconnection error:', error);
});

apiWebSocket.on('reconnect_failed', () => {
  console.error('Failed to reconnect');
});

let pingTimeout = null;
apiWebSocket.on('ping', (evt) => {
  isConnected = true;
  console.log('Ping received', evt?.interval);

  if (pingTimeout) clearTimeout(pingTimeout);
  pingTimeout = setTimeout(() => {
    console.error('Ping timeout - force reconnect');
    manualReconnect({ delay: 0, forceNew: true });
  }, evt?.interval ? (evt?.interval + 10_000) : 60_000);

  // Send pong back to server
  apiWebSocket.emit('pong');
});

let manualReconnectScheduledAt = null;
let manualReconnectTimeout = null;
function manualReconnect({ attempt = 0, delay = 2000, connectionCheckWait = 2000, forceNew = false } = {}) {
  const now = Date.now();
  const newScheduledAt = now + delay;
  // If a manual reconnect is already scheduled, only reschedule if the new delay is shorter
  if (manualReconnectScheduledAt && (newScheduledAt > manualReconnectScheduledAt)) return;
  manualReconnectScheduledAt = newScheduledAt;
  if (manualReconnectTimeout) clearTimeout(manualReconnectTimeout);
  manualReconnectTimeout = setTimeout(() => {
    manualReconnectScheduledAt = null;
    manualReconnectTimeout = null;
    if (!isConnected || forceNew) {
      console.log('Attempting manual reconnection...', attempt);
      if (forceNew) apiWebSocket.disconnect();
      apiWebSocket.connect();
    }
    // Timeout to give it a chance to connect before checking if connected
    setTimeout(() => {
      if (isConnected) return;
      // Timeout with exponential backoff to try again
      manualReconnect({ attempt: attempt + 1, delay: 1000 * Math.pow(2, Math.min(attempt, 6)), connectionCheckWait });
    }, connectionCheckWait);
  }, delay);
}

export const apiWebSocketEmit = (event, payload) => apiWebSocket.emit(event, payload);

export const apiWebSocketCallback = (event, callback) => apiWebSocket.on(event, data => callback(data));
