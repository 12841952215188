export const EVENT = {
  RECEIVED: {
    CALL_REQUEST_PHARMACIST_ACTIONS_CREATE: 'callRequestPharmacistActions:create',
    CALL_REQUESTS_CREATE: 'callRequests:create',
    CONNECT: 'connect',
    CONTAINERS_ASSIGNABLE: 'containers:assignable',
    RECONNECT: 'reconnect',
    DISCONNECT: 'disconnect',
    DISPENSE_ATTEMPT_INACTIVE: 'dispenseAttempts:inactive',
    DISPENSE_ATTEMPT_REVIEW_CREATE: 'dispenseAttemptReview:create',
    PRESCRIPTION_FILL_PHARMACIST_CONSULTATION_REQUESTS_CREATE: 'prescriptionFillPharmacistConsultationRequests:create',
    CONTAINER_ADD_LABELLED_IMAGE: 'containers:addLabelledImage',
    CONTAINER_ADD_PRELABELLED_IMAGE: 'containers:addPrelabelledImage',
    CONTAINER_LABELLED: 'containers:labelled',
    PATIENTS_CREATE: 'patients:create',
    PATIENTS_PHARMACY_DATA_CAPTURE: 'patients:pharmacyDataCapture',
    PHARMACISTS_SET_STATUS: 'pharmacists:setStatus',
    PRESCRIPTION_FILL_MAIL_ORDERS_CREATE: 'prescriptionFillMailOrders:create',
    PRESCRIPTION_FILL_TRANSFERS_CREATE: 'prescriptionFillTransfers:create',
    PRESCRIPTION_FILLS_SET_FULFILLMENT_TYPE: 'prescriptionFills:set:fulfillmentType',
    KIOSK_DRUG_THRESHOLDS_UPSERT: 'kioskDrugThresholds:upsert',
    KIOSK_STATUSES_CREATE: 'kioskStatuses:create',
    VIDEO_CONFERENCE_INVITATION_CREATE: 'videoConferenceInvitations:create',
    VIDEO_CONFERENCE_INVITATION_STATUS_CHANGE: 'videoConferenceInvitations:statusChange',
    VIDEO_CONFERENCE_INVITATION_REJECT: 'videoConferenceInvitations:reject',
    VIDEO_CONFERENCE_PARTICIPANT_CONNECTION_DISCONNECT: 'videoConferenceParticipantConnections:disconnect',
    VIDEO_CONFERENCE_COMPUTED_LONELY_UPDATE: 'videoConferences:computedLonely:update',
  },
  EMITTED: {
    IDENTIFY: 'identify',
  }
};

export const IDENTIFICATION_INTERVAL = 1000 * 60 * 60; // 60 minutes
